import * as React from "react";
import { Button } from "antd";
import { t } from "../../../../../translation";
import { useFormContext, useWatch } from "react-hook-form";
import { ID } from "../../../../../shared/interfaces";

interface IProps {
    disabled?: boolean;
    className?: string;
    ["data-cy"]?: string;
}

export const FormSubmitButton: React.FC<IProps> = (props) => {
    const id: ID | null = useWatch({ name: "id" });
    const { formState } = useFormContext(); // retrieve all hook methods

    let content = props.children;
    if (!content) {
        content = !!id ? t("buttons.edit") : t("buttons.create");
    }

    return (
        <Button
            loading={formState.isSubmitting}
            type="primary"
            htmlType="submit"
            {...props}
        >
            {content}
        </Button>
    );
};
