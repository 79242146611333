import * as React from "react";
import { Avatar, Menu } from "antd";
import logo from "../../../../resources/images/routes-no-background.svg";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { useHistory, useLocation } from "react-router-dom";
import {
    getAuthenticatedRoutes,
    getMatchedKeys,
} from "../../../../shared/routes";
import {
    UserAddOutlined,
    UsergroupAddOutlined,
    SettingOutlined,
} from "@ant-design/icons/lib";

import "./baseMenu.less";
import { t } from "../../../../translation";
import { StyleSheet, css } from "aphrodite";

const styles = StyleSheet.create({
    right: {
        float: "right",
    },
});

export const BaseMenu: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const logoutAction = useStoreActions((actions) => actions.auth.logout);
    const currentUser = useStoreState((state) => state.auth.currentUser);
    const authenticatedRoutes = getAuthenticatedRoutes();

    return (
        <>
            <Menu
                mode="horizontal"
                theme="light"
                onClick={(value) => {
                    const path = value.keyPath[0];
                    if (path.startsWith("item_")) {
                        return;
                    }
                    history.push(`${path}`);
                }}
                selectedKeys={getMatchedKeys(location)}
                className="menu-base"
            >
                <Menu.Item key={authenticatedRoutes.root.path}>
                    <img src={logo} className="img-menu-item" alt="logo" />
                </Menu.Item>
                {currentUser?.hasPermission("users", "view") && (
                    <Menu.Item key={authenticatedRoutes.routes.path}>
                        <UserAddOutlined />
                        {t("navigation.routes")}
                    </Menu.Item>
                )}
                {currentUser?.hasPermission("users", "view") && (
                    <Menu.Item key={authenticatedRoutes.users.path}>
                        <UserAddOutlined />
                        {t("navigation.users")}
                    </Menu.Item>
                )}
                {currentUser?.hasPermission("groups", "view") && (
                    <Menu.Item key={authenticatedRoutes.groups.path}>
                        <UsergroupAddOutlined />
                        {t("navigation.groups")}
                    </Menu.Item>
                )}
                {currentUser?.isSuperuser && (
                    <Menu.Item key="/admin">
                        <SettingOutlined />
                        {t("navigation.admin")}
                    </Menu.Item>
                )}
                <Menu.Item
                    key={authenticatedRoutes.logout.path}
                    onClick={() => {
                        logoutAction().then((loggedOut) => {
                            if (loggedOut) {
                                history.push("/");
                                window.location.reload();
                            }
                        });
                    }}
                >
                    {t("navigation.logout")}
                </Menu.Item>
                <Menu.Item className={css(styles.right)} disabled={true}>
                    <Avatar size="large" className="menu-ant-avatar">
                        {currentUser?.firstName
                            ? currentUser.firstName[0]
                            : currentUser?.username[0]}
                    </Avatar>
                </Menu.Item>
                <Menu.Item
                    className={css(styles.right)}
                    onClick={() => window.open("https://inogo.nl/")}
                >
                    Ontwikkeld door: InoGo Software
                </Menu.Item>
            </Menu>
            <div className="menu-filler" />
        </>
    );
};
