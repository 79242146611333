import * as React from "react";
import { Typography } from "antd";
import { t } from "../../../translation";
import { ConfirmPasswordResetForm } from "../components/ConfirmPasswordReset";
import { BaseUnauthenticatedLayout } from "../../base/components/BaseUnauthenticatedLayout";

interface IProps {
    match: { params: { token: string } };
}

export const ConfirmPasswordResetScreen: React.FC<IProps> = (props) => {
    const token = props.match.params.token;

    return (
        <BaseUnauthenticatedLayout>
            <Typography.Title>
                {t("users.auth.confirmPasswordReset.title")}
            </Typography.Title>
            <ConfirmPasswordResetForm token={token} />
        </BaseUnauthenticatedLayout>
    );
};
