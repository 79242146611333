export const buttonsTranslationEn = {
    create: "Create",
    update: "Update",
    delete: "Delete",
    edit: "Edit",
    reset: "Reset",
    request: "Request",
    view: "View",
    download: "Download",
    add: "Add",
    send: "Send",
};
