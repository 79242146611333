import * as Yup from "yup";
import { Modal } from "antd";
import { AxiosError } from "axios";

type Errors = { [key: string]: any | Errors };

// FIXME: Type the form errors correctly.
interface IFormErrors {
    [key: string]: string;
}

const getErrors = (errors: Errors) => {
    const formattedErrors: Errors = {};
    Object.keys(errors).forEach((key) => {
        const error = errors[key];

        // Check if its an array
        if (!Array.isArray(error)) {
            formattedErrors[key] = getErrors(error);
            return;
        }

        if (error.length === 0) {
            return;
        }

        if (typeof error[0] === "string") {
            formattedErrors[key] = error;
            return;
        } else if (typeof error[0] === "object") {
            formattedErrors[key] = getErrors(error);
            return;
        }

        formattedErrors[key] = error.map((err) =>
            typeof err === "string" ? err : "Unknown validation error"
        );
    });
    return formattedErrors;
};

export const formatBackendValidationErrors = <T>(
    errors: any
): IFormErrors | T => {
    return getErrors(errors) as IFormErrors;
};

export const setBackendErrors = (helper: any, error: any) => {
    if (error.response && error.response.status === 400) {
        const validationErrors = formatBackendValidationErrors(
            error.response.data
        );
        helper.setErrors(validationErrors);
    }
    helper.setSubmitting(false);
};

export const showBackendValidationErrors = (
    error: AxiosError,
    title: string
) => {
    if (
        error &&
        error.response &&
        error.response.data &&
        error.response.status === 400
    ) {
        const formattedErrors = Object.values(
            formatBackendValidationErrors(error.response.data)
        ).flatMap((errors) => errors);

        Modal.error({
            title,
            content: formattedErrors.join("\n"),
        });
    }
};

type SchemaType<T extends object> = {
    [key in keyof T]: Yup.Schema<T[key]>;
};

export const getTypedSchema = <T extends object>(
    schema: SchemaType<T>
): Yup.ObjectSchema<T> => Yup.object(schema);
