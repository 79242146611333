import * as React from "react";
import { useStoreActions } from "../../../store/hooks";
import { User } from "../../auth/models";
import { EditUserForm } from "../components/EditUserForm";
import { useHistory } from "react-router-dom";
import { getAuthenticatedPath } from "../../../shared/routes";
import { BaseEditScreen } from "../../base/components/BaseEditScreen";
import { Divider } from "antd";

interface IProps {
    match: { params: { id: number | undefined } };
}

export const UserEditScreen: React.FC<IProps> = (props) => {
    const history = useHistory();

    const { getItems } = useStoreActions((actions) => actions.normalize);

    return (
        <BaseEditScreen<User>
            match={props.match}
            subState="users"
            editComponent={(user) => (
                <>
                    <EditUserForm
                        user={user}
                        onClose={() => {
                            if (!user) {
                                getItems({ subState: "users" });
                            }
                            history.push(getAuthenticatedPath("users"));
                        }}
                    />
                    <Divider />
                </>
            )}
        />
    );
};
