import { buttonsTranslationNl } from "./buttons";
import { usersTranslationNL } from "./users";
import { filtersTranslationNl } from "./filters";
import { navigationTranslationNl } from "./navigation";
import { groupsTranslationNL } from "./groups";
import { formsTranslationNl } from "./forms";
import { httpTranslationNl } from "./http";
import { homeTranslationNl } from "./home";

export const TranslationNl = {
    buttons: buttonsTranslationNl,
    filters: filtersTranslationNl,
    navigation: navigationTranslationNl,
    users: usersTranslationNL,
    groups: groupsTranslationNL,
    forms: formsTranslationNl,
    http: httpTranslationNl,
    home: homeTranslationNl,
};
