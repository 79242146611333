import { User } from "../../auth/models";
import * as Yup from "yup";
import { getTypedSchema } from "../../base/helpers/formErrorHelpers";

export interface IUserForm {
    id: number | null;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    groups: number[];
}

export const getUserInitialValues = (): IUserForm => ({
    id: null,
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    groups: [],
});

export const userToValues = (user: User): IUserForm => ({
    id: user.id,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    groups: user.groups.map((group) => group.id),
});

export const getUserValidationSchema = () =>
    getTypedSchema<IUserForm>({
        id: Yup.number().nullable(),
        username: Yup.string()
            .min(2)
            .max(50)
            .required(),
        firstName: Yup.string()
            .min(2)
            .max(50)
            .required(),
        lastName: Yup.string()
            .min(2)
            .max(50)
            .required(),
        email: Yup.string()
            .email()
            .required(),
        groups: Yup.array().of(Yup.number()),
    });
