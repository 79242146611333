import { HomeScreen, NotFoundScreen } from "../../modules/base/screens";
import { getRoutes, IRoute } from "./sharedRoutes";
import {
    GroupOverviewScreen,
    UserEditScreen,
    UserOverviewScreen,
} from "../../modules/user/screens";
import { t } from "../../translation";
import { ID } from "../interfaces";
import qs from "qs";
import { ManageScreen } from "../../modules/manage/screens/ManageScreen";
import { RouteScreen } from "../../modules/routes/screens/RouteScreen";

type AuthenticatedRoutes =
    | "root"
    | "manage"
    | "users"
    | "users-new"
    | "users-detail"
    | "groups"
    | "routes"
    | "logout"
    | "notFound";

export const getAuthenticatedRoutes = (): {
    [key: string]: IRoute<AuthenticatedRoutes>;
} => ({
    root: {
        component: HomeScreen,
        exact: true,
        path: "/",
        key: "root",
        title: "",
    },
    routes: {
        component: RouteScreen,
        exact: true,
        path: "/routes",
        key: "routes",
        title: "Routes",
    },
    manage: {
        component: ManageScreen,
        exact: true,
        path: "/manage",
        key: "manage",
        title: t("navigation.admin"),
    },
    users: {
        component: UserOverviewScreen,
        exact: false,
        path: "/users",
        key: "users",
        title: t("navigation.users"),
        childs: [
            {
                component: UserEditScreen,
                exact: true,
                path: "/new",
                key: "users-new",
                title: t("navigation.create"),
            },
            {
                component: UserEditScreen,
                exact: true,
                path: "/:id",
                key: "users-detail",
                title: t("navigation.edit"),
            },
        ],
    },
    groups: {
        component: GroupOverviewScreen,
        exact: true,
        path: "/groups",
        key: "groups",
        title: t("navigation.groups"),
    },
    logout: {
        component: undefined,
        path: "/logout",
        key: "logout",
        title: t("navigation.logout"),
    },
    notFound: {
        component: NotFoundScreen,
        path: undefined,
        key: "notFound",
        title: t("navigation.notFound"),
    },
});

export const getAuthenticatedPath = (
    key: AuthenticatedRoutes,
    ids?: { [key: string]: ID },
    params?: { [key: string]: string }
): string => {
    const routes = getRoutes(getAuthenticatedRoutes());
    if (key in routes && routes[key].path) {
        let route = routes[key].path;

        if (!route) {
            return "/";
        }

        if (ids) {
            for (const [_key, value] of Object.entries(ids)) {
                route = route.replace(`:${_key}`, value.toString());
            }
        }

        if (params) {
            route += qs.stringify(params, { addQueryPrefix: true });
        }

        return route;
    }
    return "/";
};
