import React, { useEffect } from "react";
import { Button, Table } from "antd";
import {
    EyeInvisibleOutlined,
    EyeOutlined,
    FileOutlined,
    FolderOutlined,
} from "@ant-design/icons";
import { HttpClient } from "../../../shared/networking";
import { Route } from "../models/Route";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { getActiveItems } from "../../normalized/selectors/getItemsSelector";
import { Directory } from "../models/Directory";

interface IFileBrowserItem {
    key: string;
    name: string;
    isDirectory: boolean;
    visibleOnMap: boolean | undefined;
    directory: Directory | null;
    route: Route | null;
}

export const FileBrowser: React.FC = () => {
    const { getItems, updateItem } = useStoreActions(
        (actions) => actions.normalize
    );
    const routes: Route[] = useStoreState((state) =>
        getActiveItems(state, "routes")
    );
    const directories: Directory[] = useStoreState((state) =>
        getActiveItems(state, "directories")
    );
    const loading = useStoreState(
        (state) =>
            state.normalize.meta.routes.get.loading ||
            state.normalize.meta.directories.get.loading
    );

    const [folderPath, setFolderPath] = React.useState<Directory[]>([]);
    const [selectedKeys, setSelectedKeys] = React.useState<React.Key[]>([]);

    const currentFolder =
        folderPath.length > 0 ? folderPath[folderPath.length - 1] : null;

    const currentPath =
        folderPath.reduce((prev, cur) => `${prev}/${cur.name}`, "") || "/";

    const fetch = () => {
        getItems({
            subState: "routes",
            extraFilters: {
                directory: currentFolder ? currentFolder.id : "root",
            },
        });

        getItems({
            subState: "directories",
            extraFilters: {
                parent: currentFolder ? currentFolder.id : "root",
            },
        });
    };

    useEffect(() => {
        fetch();
    }, [folderPath]);

    const currentItems: IFileBrowserItem[] = React.useMemo(
        () => [
            ...directories.map((directory) => ({
                key: directory.id,
                isDirectory: true,
                name: directory.name,
                visibleOnMap: undefined,
                directory,
                route: null,
            })),
            ...routes.map((route) => ({
                key: route.id,
                isDirectory: false,
                name: route.name,
                visibleOnMap: route.visibleOnMap,
                directory: null,
                route,
            })),
        ],
        [routes, directories]
    );

    const uploadGpx = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("file", event.target!.files![0]);
        formData.append("directory_id", currentFolder ? currentFolder.id : "");

        HttpClient.post<{ routes: Route[] }>("/api/routes/gpx/", formData, {
            config: {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        }).then(() => fetch());
    };

    const goToDirectory = (newDirectory: Directory) => {
        setFolderPath((old) => [...old, newDirectory]);
    };

    const flipVisibility = (route: Route) => {
        updateItem({
            subState: "routes",
            id: route.id,
            data: {
                visibleOnMap: !route.visibleOnMap,
            },
            originalObject: {},
        });
    };

    return (
        <>
            <Table<IFileBrowserItem>
                dataSource={currentItems || []}
                loading={loading}
                pagination={false}
                rowSelection={{
                    onChange: (newKeys) => setSelectedKeys(newKeys),
                    selectedRowKeys: selectedKeys,
                }}
                title={() => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                            type="default"
                            onClick={() =>
                                setFolderPath((old) =>
                                    old.slice(0, old.length - 1)
                                )
                            }
                            disabled={folderPath.length === 0}
                        >
                            Terug
                        </Button>
                        <span style={{ marginLeft: "1em" }}>{currentPath}</span>
                    </div>
                )}
                onRow={(record) => ({
                    onDoubleClick: () => {
                        if (record.directory) {
                            goToDirectory(record.directory);
                        }
                    },
                    style: {
                        cursor: "pointer",
                    },
                })}
                footer={() => (
                    <>
                        <input
                            type="file"
                            onChange={(e) => uploadGpx(e)}
                            style={{ zIndex: 999 }}
                        />
                    </>
                )}
            >
                <Table.Column<IFileBrowserItem>
                    key="visibleOnMap"
                    dataIndex="visibleOnMap"
                    title=""
                    width="50px"
                    render={(visibleOnMap, record) => {
                        if (visibleOnMap === undefined || !record.route) {
                            return null;
                        }

                        return (
                            <span onClick={() => flipVisibility(record.route!)}>
                                {visibleOnMap ? (
                                    <EyeOutlined />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )}
                            </span>
                        );
                        return;
                    }}
                />
                <Table.Column
                    key="isDirectory"
                    dataIndex="isDirectory"
                    title=""
                    width="50px"
                    render={(isDirectory: boolean) =>
                        isDirectory ? <FolderOutlined /> : <FileOutlined />
                    }
                />
                <Table.Column key="name" dataIndex="name" title="Name" />
            </Table>
        </>
    );
};
