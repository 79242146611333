import * as React from "react";
import { Form } from "antd";
import {
    useForm,
    FormProvider,
    FieldValues,
    DefaultValues,
    UnpackNestedValue,
    Path,
} from "react-hook-form";
import { FormContext } from "..";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProps } from "antd/es/form";
import { formatBackendValidationErrors } from "../../../helpers/formErrorHelpers";
import { AxiosError } from "axios";

interface IProps<T extends FieldValues> {
    children: () => React.ReactNode;
    defaultValues: DefaultValues<T>;
    onSubmit: (values: UnpackNestedValue<T>) => Promise<void>;
    validationSchema?: Yup.ObjectSchema<T>;
    readonly?: boolean;
}

export const BaseFormProvider = <T extends FieldValues>(
    props: IProps<T> & FormProps
) => {
    const form = useForm<T>({
        defaultValues: props.defaultValues,
        resolver: props.validationSchema
            ? yupResolver(props.validationSchema)
            : undefined,
        mode: "onChange",
    });

    const onError = (error: AxiosError) => {
        if (error.response && error.response.status === 400) {
            const validationErrors = formatBackendValidationErrors<T>(
                error.response.data
            );

            Object.entries(validationErrors).forEach(([key, value]) => {
                form.setError(key as Path<T>, {
                    message: value[0],
                });
            });
        }
    };

    const onFinish = () => {
        form.handleSubmit((newValues) =>
            props.onSubmit(newValues)
        )().catch((error) => onError(error));
    };
    return (
        <FormContext.Provider value={{ readOnly: !!props.readonly }}>
            <FormProvider {...form}>
                <Form
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={() => onFinish()}
                    {...props}
                >
                    {props.children}
                </Form>
            </FormProvider>
        </FormContext.Provider>
    );
};
