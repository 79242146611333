import * as React from "react";

import { MailOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { getUnAuthenticatedPath } from "../../../../shared/routes";
import { t } from "../../../../translation";
import { Button } from "antd";
import * as Yup from "yup";
import { css, StyleSheet } from "aphrodite";
import {
    BaseFormProvider,
    FormInputItem,
    FormSubmitButton,
} from "../../../base/components/BaseFormV2";
import { getTypedSchema } from "../../../base/helpers/formErrorHelpers";
import { useStoreActions } from "../../../../store/hooks";

const initialData = {
    email: "",
};

export type InitialDataPasswordResetForm = typeof initialData;

const styles = StyleSheet.create({
    button: {
        marginTop: "0.5em",
        width: "100%",
    },
});

export const PasswordResetForm: React.FC = () => {
    const history = useHistory();

    const passwordResetAction = useStoreActions(
        (actions) => actions.auth.passwordReset
    );

    const onSubmit = async (values: InitialDataPasswordResetForm) => {
        return passwordResetAction(values).then(() => history.push("/"));
    };

    return (
        <BaseFormProvider<InitialDataPasswordResetForm>
            defaultValues={initialData}
            onSubmit={onSubmit}
            validationSchema={getTypedSchema<InitialDataPasswordResetForm>({
                email: Yup.string().required(),
            })}
            labelCol={{
                span: 0,
            }}
            wrapperCol={{
                span: 24,
            }}
        >
            {() => (
                <>
                    <FormInputItem
                        name="email"
                        prefix={
                            <MailOutlined className="site-form-item-icon" />
                        }
                        placeholder={t("users.fields.email")}
                    />
                    <FormSubmitButton className={css(styles.button)}>
                        {t("buttons.request")}
                    </FormSubmitButton>
                    <Button
                        type="default"
                        onClick={() =>
                            history.push(getUnAuthenticatedPath("login"))
                        }
                        className={css(styles.button)}
                    >
                        {t("users.auth.title")}
                    </Button>
                </>
            )}
        </BaseFormProvider>
    );
};
