import React from "react";
import { MapContainer, Polyline, Popup, TileLayer } from "react-leaflet";
import { InternalMap } from "./InternalMap";
import { Route } from "../models/Route";
import { useStoreState } from "../../../store/hooks";
import { getItemsSelector } from "../../normalized/selectors/getItemsSelector";
import { useNormalizedState } from "../../base/hooks";

export const Map: React.FC = () => {
    const [center] = React.useState<{ lat: number; lng: number }>({
        lat: 52.2129919,
        lng: 5.2793703,
    });

    useNormalizedState("routes", {
        alwaysFetch: true,
        extraFilters: {
            visibleOnMap: true,
        },
    });

    const routes = useStoreState((state) =>
        getItemsSelector<Route>(state, "routes").filter(
            (route) => route.visibleOnMap
        )
    );

    return (
        <div>
            <MapContainer
                center={center}
                zoom={7}
                scrollWheelZoom={true}
                style={{
                    minHeight: "calc(100vh - 250px)",
                }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <InternalMap />
                {routes.map((route) => (
                    <Polyline
                        key={route.name}
                        positions={route.polyline}
                        color={route.color}
                    >
                        <Popup>{route.name}</Popup>
                    </Polyline>
                ))}
            </MapContainer>
        </div>
    );
};
