import {
    loginThunk,
    logoutThunk,
    getCurrentUserThunk,
    getCurrentTenantThunk,
} from "./thunks";
import { action, Action } from "easy-peasy";
import { Tenant, User } from "./models";
import { setTokenDataAction } from "./actions";
import * as Sentry from "@sentry/browser";
import { getSentryDsn } from "../../shared/functions";
import { hijackLoginThunk } from "./thunks/hijackLoginThunk";
import { passwordResetThunk } from "./thunks/passwordResetThunk";
import { confirmPasswordResetThunk } from "./thunks/confirmPasswordResetThunk";

export interface IAuthState {
    /**
     * State
     */
    loginLoading: boolean;
    loginError: Error | null;

    passwordResetLoading: boolean;
    passwordResetError: Error | null;

    confirmPasswordResetLoading: boolean;
    confirmPasswordResetError: Error | null;

    setTokenDone: boolean;
    token: string | null;

    getCurrentUserLoading: boolean;
    getCurrentUserDone: boolean;
    getCurrentUserError: Error | null;
    currentUser: User | null;

    getCurrentTenantLoading: boolean;
    getCurrentTenantDone: boolean;
    getCurrentTenantError: Error | null;
    currentTenant: Tenant | null;

    /**
     * Thunks
     */
    login: typeof loginThunk;
    hijackLogin: typeof hijackLoginThunk;
    logout: typeof logoutThunk;
    getCurrentUser: typeof getCurrentUserThunk;
    getCurrentTenant: typeof getCurrentTenantThunk;
    passwordReset: typeof passwordResetThunk;
    confirmPasswordReset: typeof confirmPasswordResetThunk;

    /**
     * Actions
     */
    loginBegin: Action<IAuthState>;
    loginSuccess: Action<IAuthState>;
    loginFailure: Action<IAuthState, Error>;

    passwordResetBegin: Action<IAuthState>;
    passwordResetSuccess: Action<IAuthState>;
    passwordResetFailure: Action<IAuthState, Error>;

    confirmPasswordResetBegin: Action<IAuthState>;
    confirmPasswordResetSuccess: Action<IAuthState>;
    confirmPasswordResetFailure: Action<IAuthState, Error>;

    setTokenData: typeof setTokenDataAction;

    clearCurrentUser: Action<IAuthState>;
    getCurrentUserBegin: Action<IAuthState>;
    getCurrentUserSuccess: Action<IAuthState, User>;
    getCurrentUserFailure: Action<IAuthState, Error>;

    getCurrentTenantBegin: Action<IAuthState>;
    getCurrentTenantSuccess: Action<IAuthState, Tenant>;
    getCurrentTenantFailure: Action<IAuthState, Error>;
}

export const authState: IAuthState = {
    /**
     * State
     */
    loginLoading: false,
    loginError: null,

    passwordResetLoading: false,
    passwordResetError: null,

    confirmPasswordResetLoading: false,
    confirmPasswordResetError: null,

    setTokenDone: false,
    token: "",

    getCurrentUserLoading: false,
    getCurrentUserDone: false,
    getCurrentUserError: null,
    currentUser: null,

    getCurrentTenantLoading: false,
    getCurrentTenantDone: false,
    getCurrentTenantError: null,
    currentTenant: null,

    /**
     * Thunks
     */
    login: loginThunk,
    hijackLogin: hijackLoginThunk,
    logout: logoutThunk,
    getCurrentUser: getCurrentUserThunk,
    getCurrentTenant: getCurrentTenantThunk,
    passwordReset: passwordResetThunk,
    confirmPasswordReset: confirmPasswordResetThunk,

    /**
     * Actions
     */
    loginBegin: action((state) => {
        state.loginError = null;
        state.loginLoading = true;
    }),
    loginSuccess: action((state) => {
        state.loginLoading = false;
    }),
    loginFailure: action((state, payload) => {
        state.loginError = payload;
        state.loginLoading = false;
    }),
    passwordResetBegin: action((state) => {
        state.passwordResetError = null;
        state.passwordResetLoading = true;
    }),
    passwordResetSuccess: action((state) => {
        state.passwordResetLoading = false;
    }),
    passwordResetFailure: action((state, payload) => {
        state.passwordResetError = payload;
        state.passwordResetLoading = false;
    }),
    confirmPasswordResetBegin: action((state) => {
        state.confirmPasswordResetError = null;
        state.confirmPasswordResetLoading = true;
    }),
    confirmPasswordResetSuccess: action((state) => {
        state.confirmPasswordResetLoading = false;
    }),
    confirmPasswordResetFailure: action((state, payload) => {
        state.confirmPasswordResetError = payload;
        state.confirmPasswordResetLoading = false;
    }),
    setTokenData: setTokenDataAction,
    clearCurrentUser: action((state) => {
        state.currentUser = null;
        state.getCurrentUserError = null;
    }),
    getCurrentUserBegin: action((state) => {
        state.getCurrentUserLoading = true;
        state.getCurrentUserError = null;
    }),
    getCurrentUserSuccess: action((state, payload) => {
        state.currentUser = payload;
        state.getCurrentUserError = null;
        state.getCurrentUserLoading = false;
        state.getCurrentUserDone = true;

        // Set Sentry user info
        const sentryDsn = getSentryDsn();
        if (sentryDsn) {
            Sentry.setUser({
                id: payload.id.toString(),
                username: payload.username,
                email: payload.email,
            });
        }
    }),
    getCurrentUserFailure: action((state, payload) => {
        state.getCurrentUserLoading = false;
        state.getCurrentUserError = payload;
    }),
    getCurrentTenantBegin: action((state) => {
        state.getCurrentTenantLoading = true;
        state.getCurrentTenantError = null;
    }),
    getCurrentTenantSuccess: action((state, payload) => {
        state.currentTenant = payload;
        state.getCurrentTenantLoading = false;
        state.getCurrentTenantDone = true;
        state.getCurrentTenantError = null;

        document.title = payload.appName;
    }),
    getCurrentTenantFailure: action((state, payload) => {
        state.getCurrentTenantLoading = false;
        state.getCurrentTenantError = payload;
    }),
};
