import * as React from "react";
import { LoginForm } from "../components";
import { Typography } from "antd";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import {
    getAuthenticatedRoutes,
    getUnAuthenticatedRoutes,
} from "../../../shared/routes";
import * as qs from "qs";
import { t } from "../../../translation";
import { BaseUnauthenticatedLayout } from "../../base/components/BaseUnauthenticatedLayout";
import { useTenant } from "../../base/hooks";

export const LoginScreen: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const tenant = useTenant();

    const unAuthenticatedRoutes = getUnAuthenticatedRoutes();
    const authenticatedRoutes = getAuthenticatedRoutes();

    React.useEffect(() => {
        if (location.pathname === "/") {
            history.push("/login");
            return;
        }

        if (
            !location.pathname.startsWith("/login") &&
            !Object.values(unAuthenticatedRoutes).find((route) =>
                matchPath(location.pathname, route)
            )
        ) {
            if (
                Object.values(authenticatedRoutes).find((route) =>
                    matchPath(location.pathname, route)
                )
            ) {
                history.push(
                    `${unAuthenticatedRoutes.login.key}?redirect=${location.pathname}`
                );
            } else {
                history.push(`${unAuthenticatedRoutes.login.key}`);
            }
        }
    }, [location.pathname]);

    const getRedirectUrl = () => {
        if (location.search.startsWith("?")) {
            const params = qs.parse(location.search.substr(1));
            if ("redirect" in params) {
                return params.redirect;
            }
        }
        return "/";
    };

    return (
        <BaseUnauthenticatedLayout>
            <Typography.Title>
                {tenant ? `${tenant.name} - ` : ""}
                {t("users.auth.title")}
            </Typography.Title>
            <LoginForm afterLogin={() => history.push(getRedirectUrl())} />
        </BaseUnauthenticatedLayout>
    );
};
