import * as React from "react";
import { Typography } from "antd";
import { t } from "../../../translation";
import { PasswordResetForm } from "../components/PasswordReset";
import { BaseUnauthenticatedLayout } from "../../base/components/BaseUnauthenticatedLayout";

export const PasswordResetScreen: React.FC = () => {
    return (
        <BaseUnauthenticatedLayout>
            <Typography.Title>
                {t("users.auth.passwordReset.title")}
            </Typography.Title>
            <PasswordResetForm />
        </BaseUnauthenticatedLayout>
    );
};
