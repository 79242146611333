import { Schema, schema } from "normalizr";
import { User, Group } from "../../modules/auth/models";
import { INormalizedDefinition, SubState } from "./interfaces";
import { Route } from "../../modules/routes/models/Route";
import { Directory } from "../../modules/routes/models/Directory";

// Normalizer Schema's
export const groupSchema = new schema.Entity<Group>("groups");
export const userSchema = new schema.Entity<User>("users");
export const routeSchema = new schema.Entity<Route>("routes");
export const directorySchema = new schema.Entity<Directory>("directories");

// Separate lines for definitions to prevent circular references
userSchema.define({
    groups: [groupSchema],
});

export const globalSchema: { [key in SubState]: Schema[] } = {
    users: [userSchema],
    groups: [groupSchema],
    routes: [routeSchema],
    directories: [directorySchema],
};

export const globalNormalizedState: {
    [key in SubState]: INormalizedDefinition;
} = {
    users: {
        model: User,
        schema: userSchema,
        baseUrl: "users",
    },
    groups: {
        model: Group,
        schema: groupSchema,
        baseUrl: "groups",
    },
    routes: {
        model: Route,
        schema: routeSchema,
        baseUrl: "routes",
    },
    directories: {
        model: Directory,
        schema: directorySchema,
        baseUrl: "directories",
    },
};
