import * as React from "react";
import { Button } from "antd";

import { useHistory } from "react-router-dom";
import { t } from "../../../../translation";
import { getUnAuthenticatedPath } from "../../../../shared/routes";
import { css, StyleSheet } from "aphrodite";
import {
    BaseFormProvider,
    FormInputItem,
    FormSubmitButton,
} from "../../../base/components/BaseFormV2";
import { useStoreActions } from "../../../../store/hooks";
import { getTypedSchema } from "../../../base/helpers/formErrorHelpers";
import * as Yup from "yup";

interface IProps {
    token: string;
}

const initialData = {
    password: "",
    passwordConfirm: "",
    token: "",
};

const styles = StyleSheet.create({
    button: {
        width: "100%",
    },
    marginTop: {
        marginTop: "1em",
    },
});

export type InitialDataConfirmPasswordResetForm = typeof initialData;

export const ConfirmPasswordResetForm: React.FC<IProps> = (props: IProps) => {
    const history = useHistory();

    const confirmPasswordResetAction = useStoreActions(
        (actions) => actions.auth.confirmPasswordReset
    );

    const onSubmit = (values: InitialDataConfirmPasswordResetForm) => {
        return confirmPasswordResetAction({ ...values }).then(() =>
            history.push("/")
        );
    };

    return (
        <BaseFormProvider<InitialDataConfirmPasswordResetForm>
            defaultValues={{
                ...initialData,
                token: props.token,
            }}
            onSubmit={onSubmit}
            validationSchema={getTypedSchema<
                InitialDataConfirmPasswordResetForm
            >({
                password: Yup.string().min(3),
                passwordConfirm: Yup.string().min(3),
                token: Yup.string(),
            })}
            labelCol={{
                span: 0,
            }}
            wrapperCol={{
                span: 24,
            }}
        >
            {() => (
                <>
                    <FormInputItem<InitialDataConfirmPasswordResetForm>
                        name="password"
                        type="password"
                        placeholder={t("users.fields.password").toLowerCase()}
                    />
                    <FormInputItem<InitialDataConfirmPasswordResetForm>
                        name="passwordConfirm"
                        type="password"
                        placeholder={t("users.fields.password").toLowerCase()}
                    />

                    <FormSubmitButton className={css(styles.button)}>
                        {t("buttons.edit")}
                    </FormSubmitButton>
                    <Button
                        type="default"
                        className={css([styles.button, styles.marginTop])}
                        onClick={() =>
                            history.push(getUnAuthenticatedPath("login"))
                        }
                    >
                        {t("users.auth.title")}
                    </Button>
                </>
            )}
        </BaseFormProvider>
    );
};
