import * as React from "react";
import { Form } from "antd";
import { FormItemProps } from "antd/es/form";
import { FieldPath, useController, UseControllerReturn } from "react-hook-form";
import { FormContext } from "../index";
import { toSnake } from "../../../../../shared/functions/caseCovertion";

export type FieldValues = Record<string, any>;

export interface IBaseFormItem<TFieldValues extends FieldValues> {
    name: FieldPath<TFieldValues>;
    label?: string;
    formItemProps?: Omit<FormItemProps, "children">;
}

interface IProps<TFieldValues extends FieldValues>
    extends IBaseFormItem<TFieldValues> {
    render: (
        field: UseControllerReturn<TFieldValues>["field"] & {
            disabled?: boolean;
            ["data-cy"]?: string;
        },
        controller: UseControllerReturn<TFieldValues>
    ) => React.ReactNode;
}

export const BaseFormItem = <TFieldValues extends FieldValues>(
    props: IProps<TFieldValues>
) => {
    const formContext = React.useContext(FormContext);
    const controller = useController<TFieldValues>({
        name: props.name,
    });

    const dataCy = `input-${toSnake(props.name)
        .replace("_", "-")
        .replace(".", "-")}`;

    return (
        <Form.Item
            validateStatus={controller.fieldState.error ? "error" : ""}
            help={controller.fieldState.error?.message}
            label={props.label}
            {...props.formItemProps}
        >
            <>
                {props.render(
                    {
                        ...controller.field,
                        disabled: formContext.readOnly,
                        // eslint-disable-next-line
                        ["data-cy"]: dataCy,
                    },
                    controller
                )}
            </>
        </Form.Item>
    );
};
