import { removeUnwantedProperties } from "./removeUnwantedProperties";
import { keysToSnake, toSnake } from "./caseCovertion";
import * as qs from "qs";

export const isEmpty = (input: object): boolean => {
    return Object.keys(input).length === 0;
};

export const wrapValueWithArray = <T extends string>(input: {
    [key: string]: T;
}): { [key: string]: T[] } =>
    Object.keys(input).reduce((diff, key) => {
        return {
            ...diff,
            [key]: Array.isArray(input[key]) ? input[key] : [input[key]],
        };
    }, {});

export const unwrapArrayValues = <T extends string>(input: {
    [key: string]: T | T[];
}) =>
    Object.keys(input).reduce((diff, key) => {
        if (!Array.isArray(input[key])) {
            return {
                ...diff,
                [key]: input[key],
            };
        }

        return {
            ...diff,
            [key]: input[key].length > 1 ? input[key] : input[key][0],
        };
    }, {});

export const cleanFilters = <T extends string>(input: {
    [key: string]: T | T[];
}): string => {
    const unwrappedFilters = unwrapArrayValues(input);
    const removedProps = removeUnwantedProperties(unwrappedFilters);
    const snakeCaseFilters = keysToSnake(removedProps);
    const orderingKey = "ordering";
    if (orderingKey in snakeCaseFilters) {
        snakeCaseFilters[orderingKey] = toSnake(snakeCaseFilters[orderingKey]);
    }
    return qs.stringify(snakeCaseFilters, {
        addQueryPrefix: true,
        arrayFormat: "comma",
    });
};

export const prepareFiltersForUrl = <T extends string>(input: {
    [key: string]: T | T[];
}): string => {
    const unwrappedFilters = unwrapArrayValues(input);
    const removedProps = removeUnwantedProperties(unwrappedFilters);
    return qs.stringify(removedProps, { arrayFormat: "comma" });
};
