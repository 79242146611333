import * as React from "react";
import { BaseLayout } from "../components/BaseLayout";
import { Map } from "../../routes/components/Map";
import { Button, Drawer } from "antd";
import { FileBrowser } from "../../routes/components/FileBrowser";
import { BaseActionBar } from "../components/BaseActionBar";

export const HomeScreen: React.FC = () => {
    const [drawer, setDrawer] = React.useState<boolean>(false);

    return (
        <BaseLayout fluid={true}>
            <Map />
            <Drawer
                visible={drawer}
                onClose={() => setDrawer(false)}
                width="700px"
            >
                <FileBrowser />
            </Drawer>
            <BaseActionBar>
                <Button type="primary" onClick={() => setDrawer(true)}>
                    Routes
                </Button>
            </BaseActionBar>
        </BaseLayout>
    );
};
