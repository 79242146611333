import { Marker, Popup, useMap } from "react-leaflet";
import React from "react";

export const InternalMap: React.FC = () => {
    const map = useMap();

    const [currentPosition, setCurrentPosition] = React.useState<null | {
        lat: number;
        lng: number;
    }>(null);

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setCurrentPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
    }, []);

    React.useEffect(() => {
        if (currentPosition) {
            map.setView(currentPosition, 12);
        }
    }, [currentPosition]);

    return (
        <>
            {currentPosition && (
                <Marker position={currentPosition}>
                    <Popup>You are here!</Popup>
                </Marker>
            )}
        </>
    );
};
