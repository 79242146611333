import * as React from "react";
import { StyleSheet, css } from "aphrodite";
import { t } from "../../../../translation";

interface IProps {
    yesNo: boolean;
    disableColor?: boolean;
}

const styles = StyleSheet.create({
    yes: {
        color: "green",
    },
    no: {
        color: "red",
    },
});

export const YesNo: React.FC<IProps> = (props) => (
    <span
        className={
            props.disableColor ? "" : css(props.yesNo ? styles.yes : styles.no)
        }
    >
        {props.yesNo ? t("forms.yes") : t("forms.no")}
    </span>
);
