import { Button, Col, Row, Tag, Typography } from "antd";
import { ReloadOutlined } from "@ant-design/icons/lib";
import * as React from "react";
import { StyleSheet, css } from "aphrodite";
import { useStoreState } from "../../../../store/hooks";
import { FilterData, SubState } from "../../../../shared/normalizer";
import { t } from "../../../../translation";

interface IProps {
    /**
     * The SubState for which the title will be generated.
     */
    subState: SubState;
    /**
     * Reload function to reload table data.
     */
    reload: () => void;
    /**
     * Hide the reload button.
     */
    hideReload?: boolean;
    /**
     * Function to set new filterData.
     * @param filterData: Dict with the new filters.
     */
    setFilterData: (filterData: FilterData) => void;
    /**
     * Title as string to override the default title.
     */
    title?: string;
}

const styles = StyleSheet.create({
    titleNoMargin: {
        margin: 0,
    },
    right: {
        float: "right",
    },
});

export const Title: React.FC<IProps> = (props) => {
    const meta = useStoreState((state) => state.normalize.meta[props.subState]);

    /**
     * Removes a filter if it exists in the current filters.
     * @param key: string for which key should be removed.
     */
    const removeFilter = (key: string) => {
        const newFilters: FilterData = {
            ...meta.filters,
        };

        if (!(key in newFilters)) {
            return;
        }

        delete newFilters[key];

        props.setFilterData(newFilters);
    };

    return (
        <>
            <Row align="middle">
                <Col span={20}>
                    <Typography.Title
                        level={4}
                        className={css(styles.titleNoMargin)}
                    >
                        {props.title || t(`${props.subState}.title`)}
                    </Typography.Title>
                </Col>
                <Col span={4}>
                    {!props.hideReload && (
                        <Button
                            className={css(styles.right)}
                            type="default"
                            shape="circle"
                            icon={<ReloadOutlined />}
                            onClick={() => props.reload()}
                            loading={meta.get.loading}
                            data-cy="button-reload"
                        />
                    )}
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    {Object.entries(meta.filters).map(([key, value]) => (
                        <Tag
                            key={key}
                            closable={true}
                            visible={key !== "page"}
                            onClose={() => removeFilter(key)}
                        >
                            {`${key}=${
                                Array.isArray(value) ? value.join(", ") : value
                            }`}
                        </Tag>
                    ))}
                </Col>
                {Object.keys(meta.filters).length > 1 && (
                    <Col>
                        <Button
                            type="link"
                            onClick={() => {
                                props.setFilterData({
                                    page: [meta.currentPage.toString()],
                                });
                            }}
                        >
                            {t("filters.clearFilters")}
                        </Button>
                    </Col>
                )}
            </Row>
        </>
    );
};
