import { ID } from "../../../shared/interfaces";
import { SubState } from "../../../shared/normalizer";
import { useStoreActions, useStoreState } from "../../../store/hooks";
import { getActiveItems } from "../../normalized/selectors/getItemsSelector";
import * as React from "react";

export const useNormalizedState = <T extends { id: ID }>(
    subState: SubState,
    options?: {
        alwaysFetch?: boolean;
        extraFilters?: { [key: string]: any };
    }
): { data: T[]; isDone: boolean } => {
    const { getItems } = useStoreActions((actions) => actions.normalize);
    const items: T[] = useStoreState((state) =>
        getActiveItems(state, subState)
    );
    const itemDone = useStoreState(
        (state) => state.normalize.meta[subState].get.done
    );
    const currentUser = useStoreState((state) => state.auth.currentUser);

    React.useEffect(() => {
        if (!options?.alwaysFetch && itemDone) {
            return;
        }

        if (!currentUser || !currentUser.hasPermission(subState, "view")) {
            return;
        }

        getItems({ subState, extraFilters: options?.extraFilters });
    }, []);

    return { data: items, isDone: itemDone };
};
