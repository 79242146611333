import * as React from "react";
import { FileBrowser } from "../components/FileBrowser";
import { BaseLayout } from "../../base/components/BaseLayout";

export const RouteScreen: React.FC = () => {
    return (
        <BaseLayout fluid={true}>
            <FileBrowser />
        </BaseLayout>
    );
};
