import * as React from "react";
import { NormalizeItemBase, SubState } from "../../../../shared/normalizer";
import { useStoreActions } from "../../../../store/hooks";
import * as Yup from "yup";
import { logger } from "../../../../shared/logging";
import { Col, Row } from "antd";
import { DefaultValues, UnpackNestedValue } from "react-hook-form";
import { BaseFormProvider, FormCleaners, InitialDataObject } from "./index";
import { css, StyleSheet } from "aphrodite";
import { FormSubmitButton } from "./FormSubmitButton";
import { FormProps } from "antd/es/form";

interface IProps<T extends InitialDataObject> {
    /**
     * The SubState for which the form is created.
     */
    subState: SubState;
    /**
     * The initialValues for which the form is created.
     */
    initialValues: DefaultValues<T>;
    /**
     * The validationSchema for the form.
     */
    validationSchema: Yup.ObjectSchema<T>;
    /**
     * This function will be called after the form is submitted.
     * @param values: The new values.
     */
    onClose?: (values: T) => void;
    /**
     * Extra props for the Form component.
     */
    extraFormProps?: FormProps;
    /**
     * If the Submit button should NOT be rendered.
     */
    disableSubmitButton?: boolean;
    /**
     * Disable the whole form for readOnly state.
     */
    readonly?: boolean;
    /**
     * extraActions.
     */
    extraActions?: () => void;
}

const styles = StyleSheet.create({
    submit: {
        width: "100%",
    },
});

export const BaseForm = <T extends InitialDataObject>(
    props: React.PropsWithChildren<IProps<T>>
) => {
    const { createItem, updateItem } = useStoreActions(
        (actions) => actions.normalize
    );

    const onSubmit = async (values: UnpackNestedValue<T>): Promise<void> => {
        const create: boolean = values.id === null;

        // Clean form if there is a cleaner available for the subState.
        const cleanedValues =
            props.subState in FormCleaners
                ? FormCleaners[props.subState](values)
                : values;

        logger.info(
            props.subState,
            create ? "create" : "update",
            cleanedValues
        );

        const onSuccess = (data: NormalizeItemBase) => {
            if (props.onClose) {
                props.onClose(Object.values(data[props.subState])[0] as T);
            }

            return true;
        };

        if (cleanedValues.id === null) {
            await createItem({
                subState: props.subState,
                data: cleanedValues,
            }).then(onSuccess);
        } else {
            await updateItem({
                subState: props.subState,
                id: cleanedValues.id,
                data: cleanedValues,
                originalObject: props.initialValues,
            }).then(onSuccess);
        }
    };

    return (
        <BaseFormProvider
            defaultValues={props.initialValues}
            validationSchema={props.validationSchema}
            onSubmit={onSubmit}
            {...props.extraFormProps}
        >
            {() => (
                <>
                    {props.children}

                    {!props.disableSubmitButton && !props.readonly && (
                        <Row>
                            <Col xs={0} md={14} xl={16} xxl={18} />
                            <Col xs={24} md={10} xl={8} xxl={6}>
                                <FormSubmitButton
                                    className={css(styles.submit)}
                                    data-cy="button-submit"
                                />
                            </Col>
                        </Row>
                    )}

                    {props.extraActions && props.extraActions()}
                </>
            )}
        </BaseFormProvider>
    );
};
