import { Thunk, thunk } from "easy-peasy";
import { HttpClient } from "../../../shared/networking";
import { RootState } from "../../../store/storeModel";
import { INormalizeState } from "../state";
import { logger } from "../../../shared/logging";
import {
    getDefinitionForSubState,
    NormalizeItemBase,
    SubState,
} from "../../../shared/normalizer";
import { Notifier } from "../../../shared/functions";
import { ID } from "../../../shared/interfaces";
import { showBackendValidationErrors } from "../../base/helpers/formErrorHelpers";
import { t } from "../../../translation";

interface IPayload {
    subState: SubState;
    id: ID;
}

/**
 * Generic function to delete an item and remove it in normalized state
 */
export const deleteItemThunk: Thunk<
    INormalizeState,
    IPayload,
    any,
    RootState,
    Promise<NormalizeItemBase>
> = thunk((actions, payload) => {
    actions.deleteItemBegin({ subState: payload.subState });
    const definition = getDefinitionForSubState(payload.subState);

    return HttpClient.delete<NormalizeItemBase>(
        `/api/${definition.baseUrl}/${payload.id}/`
    )
        .then((data) => {
            Notifier.success("http.crud.delete.success");
            actions.deleteItemSuccess({
                subState: payload.subState,
                id: payload.id,
            });
            return data;
        })
        .catch((error) => {
            logger.error(error);
            Notifier.error("http.crud.delete.error");
            actions.deleteItemFailure({ error, subState: payload.subState });

            showBackendValidationErrors(error, t("http.crud.delete.error"));
            throw error;
        });
});
