import * as React from "react";
import { BaseFormItem, FieldValues, IBaseFormItem } from "../BaseFormItem";
import { Select } from "antd";
import { SelectProps } from "antd/es/select";
import { ID } from "../../../../../shared/interfaces";

interface IProps<TFieldValues extends FieldValues>
    extends IBaseFormItem<TFieldValues>,
        SelectProps<ID> {}

export const FormSelectItem = <TFieldValues extends FieldValues = FieldValues>(
    props: IProps<TFieldValues>
) => {
    return (
        <BaseFormItem<TFieldValues>
            name={props.name}
            label={props.label}
            formItemProps={props.formItemProps}
            render={(field) => <Select<ID> {...props} {...field} />}
        />
    );
};
