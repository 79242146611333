import * as React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getUnAuthenticatedPath } from "../../../../shared/routes";
import { t } from "../../../../translation";
import * as Yup from "yup";
import { css, StyleSheet } from "aphrodite";
import {
    BaseFormProvider,
    FormInputItem,
    FormSubmitButton,
    FormSwitchItem,
} from "../../../base/components/BaseFormV2";
import { getTypedSchema } from "../../../base/helpers/formErrorHelpers";
import { useStoreActions } from "../../../../store/hooks";

const initialData = {
    username: "",
    password: "",
    remember: true,
};

export type InitialLoginFormData = typeof initialData;

const styles = StyleSheet.create({
    horizontal: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    button: {
        marginTop: "0.5em",
        width: "100%",
    },
});

interface IProps {
    afterLogin: () => void;
}

export const LoginForm: React.FC<IProps> = (props) => {
    const loginAction = useStoreActions((actions) => actions.auth.login);

    const onSubmit = async (values: InitialLoginFormData) => {
        return loginAction(values).then(() => {
            props.afterLogin();
        });
    };

    return (
        <BaseFormProvider<InitialLoginFormData>
            defaultValues={initialData}
            onSubmit={onSubmit}
            validationSchema={getTypedSchema<InitialLoginFormData>({
                username: Yup.string().required(),
                password: Yup.string().required(),
                remember: Yup.boolean(),
            })}
            labelCol={{
                span: 0,
            }}
            wrapperCol={{
                span: 24,
            }}
        >
            {() => (
                <>
                    <FormInputItem
                        name="username"
                        prefix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder={t("users.fields.username")}
                        data-cy="input-username"
                    />
                    <FormInputItem
                        name="password"
                        prefix={
                            <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder={t("users.fields.password")}
                        data-cy="input-password"
                    />
                    <FormSwitchItem
                        name="remember"
                        formItemProps={{
                            labelCol: { span: 20 },
                            wrapperCol: { span: 4 },
                        }}
                        label={t("users.auth.rememberMe")}
                    />
                    <div className={css(styles.horizontal)}>
                        <Link to={getUnAuthenticatedPath("reset")}>
                            {t("users.auth.passwordReset.title")}
                        </Link>
                    </div>

                    <FormSubmitButton
                        className={css(styles.button)}
                        data-cy="button-submit"
                    >
                        {t("users.auth.title")}
                    </FormSubmitButton>
                </>
            )}
        </BaseFormProvider>
    );
};
