import * as React from "react";
import { BaseFormItem, FieldValues, IBaseFormItem } from "../BaseFormItem";
import { Switch } from "antd";
import { SwitchProps } from "antd/es/switch";

interface IProps<TFieldValues extends FieldValues>
    extends IBaseFormItem<TFieldValues>,
        SwitchProps {}

export const FormSwitchItem = <TFieldValues extends FieldValues = FieldValues>(
    props: IProps<TFieldValues>
) => {
    return (
        <BaseFormItem<TFieldValues>
            name={props.name}
            label={props.label}
            formItemProps={props.formItemProps}
            render={(field) => <Switch {...props} {...field} />}
        />
    );
};
