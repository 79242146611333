import { AntColumnProps } from "../types";
import { groupTableDefinition, userTableDefinition } from "./subStates";
import { FilterData } from "../../normalizer";

export interface ITableDefinition<Row, Model> {
    row: Row;
    getColumns: (
        filterData: FilterData | null,
        extra?: { [key: string]: any[] }
    ) => AntColumnProps<Row>[];
    getDataSource: (items: Model[]) => Row[];
}

export const TableDefinitions = {
    users: userTableDefinition,
    groups: groupTableDefinition,
};
