import * as React from "react";
import { User } from "../../../auth/models";
import { t } from "../../../../translation";
import { BaseTable } from "../../../base/components";
import { useHistory } from "react-router-dom";
import { getAuthenticatedPath } from "../../../../shared/routes";

export const UserTable: React.FC = () => {
    const history = useHistory();

    return (
        <>
            <BaseTable<User>
                subState="users"
                title={t("users.title")}
                enableWebSockets={true}
                onEdit={(user) => {
                    history.push(
                        getAuthenticatedPath("users-detail", { id: user.id })
                    );
                }}
                showActionBar={true}
            />
        </>
    );
};
